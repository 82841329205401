import React, { useState, Fragment } from 'react'
import { useFlexSearch } from 'react-use-flexsearch'
import { s, colors } from '../style'
import SearchResultItem from './SearchResultsItem'
import RightArrow from '../assets/RightArrow'
import LeftArrow from '../assets/LeftArrow'

function SearchResults({ searchQuery, cmsIndexStore }) {
  const [currentPage, setCurrentPage] = useState(1)

  const cmsResult = useFlexSearch(
    searchQuery,
    JSON.stringify(cmsIndexStore.index),
    cmsIndexStore.store
  )

  const resultsPerPage = 12
  const totalPages = Math.ceil(cmsResult.length / resultsPerPage)

  const indexOfLastResult = currentPage * resultsPerPage
  const indexOfFirstResult = indexOfLastResult - resultsPerPage
  const currentResults = cmsResult.slice(
    indexOfFirstResult,
    indexOfLastResult
  )

  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1)
    }
  }

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1)
    }
  }

  const goToPage = (pageNumber) => {
    setCurrentPage(pageNumber)
  }

  const renderPageNumbers = () => {
    const maxPageNumbersToShow = 5 // Change this number as desired
    const currentPageIndex = currentPage - 1
    const halfMaxPageNumbersToShow = Math.floor(maxPageNumbersToShow / 2)
    const totalPagesArray = Array.from({ length: totalPages }, (_, i) => i + 1)

    let pageNumbers = []

    if (totalPages <= maxPageNumbersToShow) {
      pageNumbers = totalPagesArray
    } else if (currentPageIndex < halfMaxPageNumbersToShow) {
      pageNumbers = [
        ...totalPagesArray.slice(0, maxPageNumbersToShow - 1),
        'ellipsis',
      ]
    } else if (currentPageIndex >= totalPages - halfMaxPageNumbersToShow) {
      pageNumbers = [
        'ellipsis',
        ...totalPagesArray.slice(totalPages - maxPageNumbersToShow + 1),
      ]
    } else {
      pageNumbers = [
        'ellipsis',
        ...totalPagesArray.slice(
          currentPageIndex - halfMaxPageNumbersToShow + 1,
          currentPageIndex + halfMaxPageNumbersToShow
        ),
        'ellipsis',
      ]
    }

    return pageNumbers.map((pageNumber, index) => {
      if (pageNumber === 'ellipsis') {
        return <span key={index}>...</span>
      }
      return (
        <button
          key={index}
          onClick={() => goToPage(pageNumber)}
          className={pageNumber === currentPage ? 'active' : ''}>
          {pageNumber}
        </button>
      )
    })
  }

  if (currentResults.length === 0) {
    return (
      <div>
        <div css={sSearchResultsInfo}>
          <p>Brak wyników wyszukiwania dla tego słowa lub frazy. Spróbuj ponownie.</p>
        </div>
      </div>
    )
  }

  return (
    <div>
      <div css={sSearchResultsInfo}>
        <p>Znaleźliśmy { 
          cmsResult.length === 1 ? "1 wynik" : 
          cmsResult.length === 2 || cmsResult.length === 3 || cmsResult.length === 4 ? `${cmsResult.length} wyniki` :
          `${cmsResult.length} wyników`
        }</p>
      </div>
      <div css={sSearchResultsWrapper}>
        {currentResults.map((result, id) => (
          <SearchResultItem key={id} data={result} searchQuery={searchQuery}/>
        ))}
        {totalPages > 1 && (
          <div css={sSearchResultsPagination}>
            <button onClick={prevPage} disabled={currentPage === 1}>
              <LeftArrow color={`#000`} />
            </button>
            {renderPageNumbers()}
            <button onClick={nextPage} disabled={currentPage === totalPages}>
              <RightArrow color={`#000`} />
            </button>
          </div>
        )}
      </div>
    </div>
  )
}

const sSearchResultsInfo = {
  margin: '2rem 0 3rem',
  [s.xs]: {
    margin: '1rem 0 2rem',
    fontSize: '14px'
  }
}

const sSearchResultsWrapper = {}

const sSearchResultsPagination = {
  marginTop: '3rem',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '10px',
  button: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '12px',
    fontWeight: '700',
    width: '36px',
    height: '36px',
    lineHeight: '36px',
    borderRadius: '50%',
    svg: {
      width: '26px',
      height: 'auto'
    },
    '&.active': {
      background: 'linear-gradient(75.88deg, #50823D -20.46%, #65BC48 49.41%, #77BD55 117.89%)',
      color: '#fff'
    },
    '&:hover': {
      backgroundColor: colors.greyLight,
      filter: 'brightness(100%)',
      color: colors.greyDarker
    }
  },
}

export default SearchResults

import React from 'react'
import { Link } from 'gatsby'
import Headline from './Headline'
import { s } from '../style'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import RightArrow from '../assets/RightArrow'


const isBrowser = typeof window !== "undefined"

function SearchResultItem({ data, searchQuery }) {

  const viewSearchResults = () => {
    if(isBrowser) {
      window.dataLayer.push({
      'event': 'view_search_results',
      'eventModel': {search_term: searchQuery}
      });
    }
  }
  
  return (
    <>
      <div css={sSearchResultItemWrapper}>
        <Link
          to={data.slug}
          onClick={viewSearchResults}
          css={[{ display: 'flex', marginBottom: '1rem' }]}>
          <Headline h={2} title={data.title} search gradient medium maxWidth />
        </Link>
        <div css={sSearchResultItemWrapperInner}>
          <Link
            to={data.slug}
            onClick={viewSearchResults}
            >
            <GatsbyImage
              image={data.image}
              alt=''
              css={sSearchItemImage}
              objectFit='cover'
            />
          </Link>
          <div css={[{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', gap: '20px', padding: '0.5rem 0', 'p': {fontSize: '14px'}}]}>
            <p>{data.description}</p>
            <Link
              to={data.slug}
              onClick={viewSearchResults}
              css={[sSearchLink]}>
                <RightArrow color={`#6BB53B`} />
              Czytaj więcej
            </Link>
          </div>
        </div>
      </div>
    </>
  )
}

const sSearchResultItemWrapper = {
  h2: {
    fontSize: '1.5rem',
    lineHeight: '1.25',
    [s.xs]: {
      fontSize: '1.15rem'
    }
  },
  padding: '2rem 0 2rem',
  borderBottom: '1px solid #6BB53B',
  ':first-of-type': {
    paddingTop: '0'
  },
  [s.xs]: {
    padding: '1.25rem 0 1rem',
  }
}

const sSearchResultItemWrapperInner = {
  display: 'grid',
  gridTemplateColumns: '225px 1fr',
  gridGap: '20px',
  [s.xs]: {
    gridTemplateColumns: '1fr'
  }
}

const sSearchItemImage = {
  position: 'relative',
  borderRadius: '1rem',
  height: '140px',
  [s.xs]: {height: '160px'}
}

const sSearchLink = {
  display: 'inline-flex',
  alignItems: 'center',
  fontSize: '14px',
  fontWeight: '700',
  transition: 'color .3s ease-in-out',
  marginLeft: '-10px',
  '&:hover': {
    color: '#6BB53B'
  },
  'svg': {
    width: '28px',
    height: 'auto'
  }
}

export default SearchResultItem

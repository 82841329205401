export const content = {
  seo: {
    title: 'Wyszukaj na stronie omida.pl',
    desc: 'Wpisz interesujące Cię słowo lub frazę i sprawdź wyniki wyszukiwania',
    url: 'wyszukaj.js'
  },
  searchbar: {
    title: 'Wyszukaj na stronie omida.pl',
    desc: 'Wpisz interesujące Cię słowo lub frazę i sprawdź wyniki wyszukiwania',
  }
}
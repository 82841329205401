import { React, useState, useEffect } from 'react'
import axios from 'axios'
import Layout from '../components/Layout'
import { graphql, useStaticQuery } from 'gatsby'
import { content } from '../content/wyszukaj'
import Seo from '../components/Seo'
import SearchBar from '../components/Search'
import SearchResults from '../components/SearchResults'
import { s, sContainer, sContainerPadding } from '../style'
import Headline from '../components/Headline'

const query = graphql`
  {
    localSearchCms {
      publicIndexURL
      publicStoreURL
    }
  }
`

const isBrowser = typeof window !== "undefined"

function Search() {

  const { search } = typeof window !== "undefined" ? window.location : '';
  const searchParams = new URLSearchParams(search);
  const params = searchParams.get('s')
  const [searchQuery, setSearchQuery] = useState(params || '')

  const data = useStaticQuery(query)
  const [cmsIndexStore, setCmsIndexStore] = useState(null)

  const {
    publicIndexURL: cmsPublicIndexURL,
    publicStoreURL: cmsPublicStoreURL,
  } = data.localSearchCms

  const handleOnFocus = async () => {
    
    if (cmsIndexStore) return

    const [{ data: cmsIndex }, { data: cmsStore }] = await Promise.all([
      axios.get(cmsPublicIndexURL),
      axios.get(cmsPublicStoreURL),
    ])

    setCmsIndexStore({
      index: cmsIndex,
      store: cmsStore,
    })
  }

  const handleOnSubmit = async (event) => {
    event.preventDefault();
    
    const [{ data: cmsIndex }, { data: cmsStore }] = await Promise.all([
      axios.get(cmsPublicIndexURL),
      axios.get(cmsPublicStoreURL),
    ]);
  
    setCmsIndexStore({
      index: cmsIndex,
      store: cmsStore,
    });

    searchParams.set('s', searchQuery);
    const newSearch = `?${searchParams.toString()}`;

    if(isBrowser) {
      window.history.replaceState(null, '', newSearch);
    }
  };

  return (
    <Layout white>
      <Seo
        title={content.seo.title}
        desc={content.seo.desc}
        image={'images/intros/omida-trade.jpg'}
        url={content.seo.url}
        noIndex
      />
      <section css={[sContainer, sContainerPadding, sSearchSection]}>
        <div css={[sSearchBarWrapper]}>
          <Headline
            h={1}
            title={content.searchbar.title}
            desc={content.searchbar.desc}
            search
            gradient
            center
            medium
            maxWidth
            searchHeadline
          />
          <SearchBar
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            actionInput={handleOnFocus}
            actionButton={handleOnSubmit}
          />
        </div>
        {searchQuery && cmsIndexStore && (
          <div css={sSearchResultsWrapper}>
            <SearchResults
              searchQuery={searchQuery}
              cmsIndexStore={cmsIndexStore}
            />
          </div>
        )}
      </section>
    </Layout>
  )
}

const sSearchSection = {
  [s.xs]: {
    paddingTop: '96px'
  }
}

const sSearchBarWrapper = {
  background:
    'linear-gradient(291.28deg, #C1C1C1 3.38%, #F2F2F2 48.41%, #FAFAFA 97.07%)',
  borderRadius: '23px',
  padding: '3rem 4rem',
  [s.sm_only]: {
    padding: '2rem 2rem',
  },
  [s.xs]: {
    padding: '1.5rem 1.5rem',
  }

}

const sSearchResultsWrapper = {}

export default Search
